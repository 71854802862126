
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&family=Roboto+Condensed:wght@400;700&display=swap');

body {
  margin: 0;
  font-family:'Noto Sans Mono','Roboto Condensed' sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #30343F;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
