.container{
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
}
p{
  width:50%;
  font-family: 'Noto Sans Mono';
  font-weight: 400;
}
h1{
  font-family: 'Roboto Condensed';
  font-weight: 700;
}
h2{
  font-family:'Roboto Condensed' ;
}
h3{
  font-family:'Roboto Condensed' ;
}
